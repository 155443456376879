import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Hotkey } from '../_models/hotkey';

@Injectable({
    providedIn: 'root',
})
export class HotkeysService {
    private hotkeys: Hotkey[] = [];
    public hotkeys$ = new BehaviorSubject<Hotkey[]>([]);
    public hotkeysObservable = this.hotkeys$.asObservable();
    private hotkey!: Hotkey;

    constructor(
        private api: ApiService,
        private http: HttpClient
    ) {
        this.hotkeys = [];
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    addHotkey(newHotkey: any): Observable<Hotkey> {
        return this.api.postAPI<Hotkey>('hotkeys', newHotkey);
    }

    getHotkeyOptionsList(): Hotkey[] {
        return this.hotkeys;
    }

    getHotkeys(): Observable<Hotkey[]> {
        const value = this.api.getAPI<Hotkey[]>('hotkeys').pipe(map((response) => response.body));
        const formattedValue = value.pipe(map((hotkeys) => this.transformData(hotkeys)));
        return formattedValue;
    }

    fetchHotkeys(): void {
        this.api.getAPI<Hotkey[]>('hotkeys').subscribe((hotkeys: Hotkey[]) => {
            this.hotkeys = hotkeys;
            this.hotkeys$.next(this.hotkeys);
        });
    }

    updateHotkey(id: number, hotkey: any): Observable<Hotkey> {
        return this.api.updateAPI<Hotkey>('hotkeys', id, hotkey);
    }

    filterHotkeys(query: string): Observable<Hotkey[]> {
        return this.getHotkeys().pipe(
            map((hotkeys) =>
                hotkeys.filter((hotkey: Hotkey) =>
                    //(hotkey.main_description || '').toLowerCase().includes(query.toLowerCase()) ||
                    //(hotkey.tag_description || '').toLowerCase().includes(query.toLowerCase())
                    (hotkey.hotkey_main || '').toLowerCase().includes(query.toLowerCase())
                )
            )
        );
    }

    deleteHotkey(id: number): Observable<Hotkey> {
        return this.api.deleteAPI<Hotkey>('hotkeys', id);
    }

    updateHotkeys(hotkeys: Hotkey[]): void {
        this.hotkeys$.next(hotkeys);
    }

    transformData(hotkeys: any): any[] {
        const mergedHotkeys: any[] = [];
    
        hotkeys.forEach((hotkey: any) => {
          const existingHotkey = mergedHotkeys.find(
            (i) => i.hotkey_main === hotkey.hotkey_main
          );
    
          if (existingHotkey) {
            // Add a new description to the existing row
            existingHotkey.descriptions.push({
              id: hotkey.id,
              hotkey_tag: hotkey.hotkey_tag,
              hotkey_main: hotkey.hotkey_main,
              main_description: hotkey.main_description,
              tag_description: hotkey.tag_description,
            });
          } else {
            // Add a new entry with a new array of descriptions
            mergedHotkeys.push({
              ...hotkey,
              descriptions: [
                {
                  id: hotkey.id,
                  hotkey_main: hotkey.hotkey_main,
                  main_description: hotkey.main_description,
                  tag_description: hotkey.tag_description,
                  hotkey_tag: hotkey.hotkey_tag,
                },
              ],
            });
          }
        });
        console.log(mergedHotkeys);
        
        return mergedHotkeys;
      }
}