import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})
export class NoteDialogComponent implements OnInit {
  dialogData: any;
  constructor(
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.dialogData = this.config.data.formdata;
  }

}
