<div
  *ngIf="this.loadingServiceReminder$ | async; else loadingServiceReminders"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>
<ng-template #loadingServiceReminders>
  <p-toast></p-toast>
  <div
    class="block overflow-hidden surface-overlay w-full"
    style="max-width: 90vw"
  >
    <p-table
      [value]="(reminders$ | async) || []"
      #signedOffIssuesTable
      [rowHover]="true"
      responsiveLayout="scroll"
      scrollHeight="75vh"
      scrollDirection="both"
      [scrollable]="true"
      [responsive]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm lg:p-datatable-md"
      [globalFilterFields]="[
        'service_delta',
        'service_due_date',
        'vehicle_reference',
        'vehicle_id',
        'km_reading'
      ]"
    >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="filterGlobal($event)"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th
          class="bg-bluegray-700 border-right-3"
            *ngFor="let col of columns"
            [class.frozen-column]="col.field === 'vehicle_reference'"
            [pSortableColumn]="col.field"
          >
            <span
              class="text-white"            
            >{{ col.header }}</span>
          </th>
          <th
            class="bg-bluegray-700"
          ></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-reminder>
        <tr>
          <td *ngFor="let col of columns">
            <span>{{ reminder[col.field] }}</span>
          </td>
          <td>
            <button
              type="button"
              pButton
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-secondary text-gray-800"
              (click)="
                menu.toggle($event);
                storeRowDetail(reminder);
                $event.stopPropagation()
              "
            ></button>
            <p-menu
              #menu
              [model]="items"
              [popup]="true"
              appendTo="body"
            ></p-menu>
            <p-menu #menu appendTo="body" [popup]="true"></p-menu>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">
            <div class="flex align -items-center justify-content-center">
              <span class="text-bluegray-800 font-medium text-xl"
                >No service reminders</span
              >
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-confirmDialog #dr key="service_reminder">
    <p-footer>
      <button
        pButton
        type="button"
        label="No"
        class="bg-bluegray-700"
        (click)="dr.reject()"
      ></button>
      <button
        pButton
        type="button"
        label="Yes"
        class="bg-bluegray-700"
        (click)="dr.accept()"
      ></button>
    </p-footer>
  </p-confirmDialog>
</ng-template>
