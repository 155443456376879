import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/_services/api.service';
import { yearModelValidator } from 'src/app/validators/yearModelValidator';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { MessageService } from 'primeng/api';
// todo: implement dynamic form generation

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit, OnChanges {
  @Input() jsonForm: any;
  @Output() formEvent = new EventEmitter<FormGroup>();
  createVehicles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  loading: boolean = false;

  vehicleForm: FormGroup = this.fb.group({
    vehicle_reference: ['', Validators.required],
    engine_no: ['', Validators.required],
    chassis_no: ['', Validators.required],
    model: ['', Validators.required],
    date_inservice: [''],
    service_interval: [1, Validators.required],
    year_model: ['', [Validators.required, yearModelValidator()]],
    active_inservice: [false, Validators.required],
    date_outservice: [''],
  });
  formData: any;

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private api: ApiService,
    private vehicleSerivce: VehiclesService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.formData = this.config.data.formdata;

    this.vehicleForm.patchValue({
      vehicle_reference: this.formData.vehicle_reference,
      engine_no: this.formData.engine_no,
      chassis_no: this.formData.chassis_no,
      model: this.formData.model,
      date_inservice: this.formData.date_inservice,
      service_interval: this.formData.service_interval,
      year_model: this.formData.year_model,
      active_inservice: this.formData.active_inservice,
      date_outservice: this.formData.date_outservice,
    });

    setTimeout(() => {
      this.createVehicles$.next(false);
    }, 1000);
  }

  ngOnChanges() {}

  onFormSubmit() {
    this.loading = true;
    if(this.vehicleForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all required fields',
      });
      this.loading = false;
      return;
    }

    
    this.vehicleSerivce.updateVehicle(this.formData.id, this.vehicleForm.value).subscribe((data) => {
      this.vehicleSerivce.getVehicles().subscribe((vehicles: any[]) => {
        this.vehicleSerivce.updateVehicles(vehicles);
      });
      this.loading = false;
      this.ref.close();
  });
    
  }
}
