import { Component, OnInit } from '@angular/core';
import { ServiceRecordsService } from 'src/app/_services/service-records.service';
import { TechnicalViewService } from 'src/app/_services/technical-view.service';
import { VehiclesService } from 'src/app/_services/vehicles.service';

@Component({
  selector: 'app-technical',
  templateUrl: './technical.component.html',
  styleUrls: ['./technical.component.scss'],
})
export class TechnicalComponent implements OnInit {
  constructor(
    public vehiclesService: VehiclesService,
    public serviceRecords: ServiceRecordsService,
    public technicalViewService: TechnicalViewService
  ) {}

  ngOnInit(): void {
    this.vehiclesService.getVehicles();
    if (this.technicalViewService.activeVehicle.vehicle_ID) {
      this.serviceRecords.getServiceRecordsforVehicle(
        this.technicalViewService.activeVehicle.vehicle_ID
      );
    }
  }
}
