<div class="flex flex-column flex-auto h-screen">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-truck text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Home</span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <!-- fleet/vehicle stats -->
        <ng-container *ngIf="loadingVehicles$ | async; else vehicleStatsLoaded">
          <div
            class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
          >
            <div class="flex align-items-center mb-3">
              <p-skeleton
                shape="circle"
                size="2rem"
                styleClass="mr-2"
              ></p-skeleton>
              <p-skeleton width="5rem" height="1.5rem"></p-skeleton>
            </div>
            <p-skeleton
              width="100%"
              height="2rem"
              styleClass="mb-4"
            ></p-skeleton>
            <div class="flex align-items-center mb-2">
              <p-skeleton
                shape="circle"
                size="2rem"
                styleClass="mr-2"
              ></p-skeleton>
              <p-skeleton width="10rem" height="1.5rem"></p-skeleton>
            </div>
            <div class="flex align-items-center">
              <p-skeleton
                shape="circle"
                size="2rem"
                styleClass="mr-2"
              ></p-skeleton>
              <p-skeleton width="10rem" height="1.5rem"></p-skeleton>
            </div>
          </div>
        </ng-container>
        <ng-template #vehicleStatsLoaded>
          <div
            class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            *ngIf="statisticsVehicles$ | async as vehicleStatistics"
          >
            <div class="flex align-items-center mb-3">
              <i class="pi pi-truck text-blue-500 text-xl mr-2"></i>
              <span class="text-500 font-medium">Vehicles</span>
            </div>
            <span class="block text-900 font-medium mb-4 text-xl">{{
              vehicleStatistics.count
            }}</span>
            <div class="flex align-items-center">
              <i class="pi pi-truck text-green-500 text-xl mr-2 mb-2"></i>
              <span class="text-green-500 font-medium"
                >{{ vehicleStatistics.active }} (Active)</span
              >
            </div>
            <div class="flex align-items-center">
              <i class="pi pi-truck text-pink-500 text-xl mr-2 mb-2"></i>
              <span class="text-pink-500 font-medium"
                >{{ vehicleStatistics.archived }} (Archived)</span
              >
            </div>
          </div>
        </ng-template>

        <!-- active issues stats -->
        <ng-container
          *ngIf="loadingActiveIssues$ | async; else activeIssuesStatsLoaded"
        >
          <div
            class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
          >
            <div class="flex align-items-center mb-3">
              <p-skeleton
                shape="circle"
                size="2rem"
                styleClass="mr-2"
              ></p-skeleton>
              <p-skeleton width="5rem" height="1.5rem"></p-skeleton>
            </div>
            <p-skeleton
              width="100%"
              height="2rem"
              styleClass="mb-4"
            ></p-skeleton>
            <div class="flex align-items-center mb-2">
              <p-skeleton
                shape="circle"
                size="2rem"
                styleClass="mr-2"
              ></p-skeleton>
              <p-skeleton width="10rem" height="1.5rem"></p-skeleton>
            </div>
            <div class="flex align-items-center">
              <p-skeleton
                shape="circle"
                size="2rem"
                styleClass="mr-2"
              ></p-skeleton>
              <p-skeleton width="10rem" height="1.5rem"></p-skeleton>
            </div>
          </div>
        </ng-container>
        <ng-template #activeIssuesStatsLoaded>
          <div
            class="border-bottom-1 border-right-none md:border-bottom-none surface-border flex-auto p-3"
            *ngIf="statisticsActiveIssues$ | async as activeIssuesStatistics"
          >
            <div class="flex align-items-center mb-3">
              <i
                class="pi pi-exclamation-triangle text-blue-500 text-xl mr-2"
              ></i>
              <span class="text-500 font-medium">Active Issues</span>
            </div>
            <span class="block text-900 font-medium mb-4 text-xl">{{
              activeIssuesStatistics.count
            }}</span>
            <div class="flex align-items-center">
              <i
                class="pi pi-exclamation-triangle text-pink-500 text-xl mr-2 mb-2"
              ></i>
              <span class="text-pink-500 font-medium"
                >{{ activeIssuesStatistics.signed_off_last_week }} (Signed off
                in the last week)</span
              >
            </div>
            <div class="flex align-items-center">
              <i
                class="pi pi-exclamation-triangle text-green-500 text-xl mr-2 mb-2"
              ></i>
              <span class="text-green-500 font-medium"
                >{{ activeIssuesStatistics.active }} (Active)</span
              >
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- vehicle actions -->

    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <p-timeline [value]="(mostRecentChangesActiveIssues$ | async) || []">
          <ng-template pTemplate="content" let-activeIssue>
            <small class="p-text-secondary">{{
              activeIssue.date_updated | dateAsAgo
            }}</small>
          </ng-template>
          <ng-template pTemplate="opposite" let-activeIssue>
            <p-chip
              [label]="
                activeIssue.vehicle_reference +
                ' - ' +
                activeIssue.signed_off +
                ' - ' +
                activeIssue.signoff_type
              "
            ></p-chip>
          </ng-template>
        </p-timeline>
      </div>
    </div>
  </div>
</div>
