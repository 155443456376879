<p-table
  *ngIf="dataConfig.dataType === 'vehicles'"
  #mainTableVehicles
  [value]="(data | async) || []"
  [(selection)]="selectedRow"
  (onRowSelect)="onRowSelect($event)"
  [scrollable]="true"
  [tableStyle]="{ 'min-width': '100vw' }"
  scrollHeight="65vh"
  selectionMode="single"
  (onContextMenu)="onContextMenu($event)"
  [contextMenu]="cm"
  [(contextMenuSelection)]="selectedRowCM"
  styleClass="normal sm:small"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Vehicle ID</th>
      <th>Vehicle Engine Number</th>
      <th>Vehicle Chassis No</th>
      <th>Vehicle Year Model</th>
      <th>Vehicle Date In Service</th>
      <th>Vehicle Service Interval</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-vehicle>
    <tr [pSelectableRow]="vehicle" [pContextMenuRow]="vehicle">
      <td>{{ vehicle.id }}</td>
      <td>{{ vehicle.engine_no }}</td>
      <td>{{ vehicle.chassis_no }}</td>
      <td>{{ vehicle.year_model }}</td>
      <td>{{ vehicle.date_inservice }}</td>
      <td>{{ vehicle.service_interval }}</td>
    </tr>
  </ng-template>
</p-table>
<p-contextMenu #cm [model]="menuItems"></p-contextMenu>

<p-table
  *ngIf="dataConfig.dataType === 'technical'"
  dataKey="url"
  #mainTable
  [value]="(data | async) || []"
  selectionMode="single"
  [(selection)]="selectedRow"
  (onRowSelect)="onRowSelect($event)"
  [scrollable]="true"
  [tableStyle]="{ 'min-width': '50rem' }"
  scrollHeight="75vh"
  (onContextMenu)="onContextMenu($event)"
  [contextMenu]="cm"
  [(contextMenuSelection)]="selectedRowCM"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5rem"></th>
      <th>Vehicle ID</th>
      <th>Date Completed</th>
      <th>Odometer</th>
      <th>Record Type</th>
      <th>Job No</th>
      <!-- <th>Vehicle Year Model</th> -->
      <!-- <th>Vehicle Date In Service</th> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-technicalrecord let-expanded="expanded">
    <tr [pSelectableRow]="technicalrecord">
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="technicalrecord"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td>{{ technicalrecord.vehicle_ID }}</td>
      <td>{{ technicalrecord.date_completed }}</td>
      <td>{{ technicalrecord.odometer }} km</td>
      <td>{{ technicalrecord.record_type }}</td>
      <td>{{ technicalrecord.service_jobno }}</td>
      <!-- <td>{{ technicalrecord.year_model }}</td> -->
      <!-- <td>{{ technicalrecord.date_inservice }}</td> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-technicalrecord>
    <tr>
      <td colspan="6">
        <div class="p-1">
          <p-table [value]="technicalrecord.job_data">
            <ng-template pTemplate="header">
              <tr>
                <th>Description</th>
                <th>Remarks</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-job>
              <tr>
                <td>{{ job.description }}</td>
                <td>{{ job.remarks }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
