import { Component, OnInit } from '@angular/core';
import { HotkeysService } from 'src/app/_services/hotkeys.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-create-tag-dialog',
  templateUrl: './create-tag-dialog.component.html',
  styleUrls: ['./create-tag-dialog.component.scss']
})
export class CreateTagDialogComponent implements OnInit {
  data: any;
  loading: boolean = false;
  tagCount: number = 0;
  mainHotkey: string = '';
  hotkeyForm = this.fb.group({
    hotkey_main: ['', Validators.required],
    hotkey_tag: ['', Validators.required],
    tag_description: ['', Validators.required],
    main_description: ['', Validators.required],
  });


  constructor(
    private hotkeysService: HotkeysService,
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public messageService: MessageService,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.hotkeyForm = this.fb.group({
      hotkey_main: ['', Validators.required],
      hotkey_tag: ['', Validators.required],
      tag_description: ['', Validators.required],
      main_description: ['', Validators.required],
    });

    this.data = this.config.data;

    this.tagCount = this.data.descriptions.length;
    this.mainHotkey = this.data.hotkey_main;
    const combined = this.mainHotkey + (this.tagCount + 1);
    

    this.hotkeyForm.patchValue({
      hotkey_main: this.data.hotkey_main,
      main_description: this.data.main_description,
      hotkey_tag: combined,
    });
  }

  createTag() {
    this.loading = true;

    if(this.hotkeyForm.invalid) {
      this.loading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all fields',
      });
      return;
    }

    this.hotkeysService.addHotkey(this.hotkeyForm.value).subscribe(
      (data) => {
        this.hotkeysService.getHotkeys().subscribe((data) => {
          this.hotkeysService.updateHotkeys(data);
        });
      },
      (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while creating the hotkey',
        });
      },
      () => {
        this.loading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Hotkey created successfully',
        });
        this.ref.close();
      }
    );
  }

}
