import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TechnicalViewService } from 'src/app/_services/technical-view.service';
import { ActiveIssueFormComponent } from '../../components/active-issues/active-issue-form/active-issue-form.component';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { IssuesService } from 'src/app/_services/issues.service';
import { SignedOffService } from 'src/app/_services/signed-off.service';

@Component({
  selector: 'app-thingstodo',
  templateUrl: './thingstodo.component.html',
  styleUrls: ['./thingstodo.component.scss'],
  providers: [DialogService],
})
export class ThingstodoComponent implements OnInit {
  dateTimeNow: any;
  signOffData: any;

  constructor(
    public dialogService: DialogService,
    public vehiclesService: VehiclesService,
    public technicalViewService: TechnicalViewService,
    public issuesService: IssuesService,
    private signOffService: SignedOffService
  ) {
    this.dateTimeNow = new Date().toISOString();
  }

  items: { label: string; icon: string }[] = [];
  activeIssues: any[] = [];
  ref: DynamicDialogRef | undefined;

  ngOnInit(): void {
    this.items = [
      { label: 'Add New', icon: 'pi pi-fw pi-plus' },
      { label: 'Remove', icon: 'pi pi-fw pi-minus' },
    ];
    
    this.signOffService.signedObservable.subscribe((res: any) => {
      this.signOffData = res;
      console.log(this.signOffData);
      
    });
    
    this.issuesService.getActiveIssues();
  }

  signOffDataOutput(data: any) {
    this.signOffData = data;
  }
}
