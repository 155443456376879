import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './main-application/pages/home/home.component';
import { TableComponent } from './main-application/components/table/table.component';
import { ThingstodoComponent } from './main-application/pages/thingstodo/thingstodo.component';
import { AuthGuard } from './_helpers';
import { VehicleDetailsComponent } from './main-application/pages/vehicle-details/vehicle-details.component';
import { ServiceReminderComponent } from './main-application/components/service-reminder/service-reminder.component';
import { ServiceRemindersComponent } from './main-application/pages/service-reminders/service-reminders.component';
import { HotkeyPageComponent } from './main-application/pages/hotkey-page/hotkey-page.component';
import { RefreshComponent } from './main-application/refresh/refresh.component';
import { ForgotPasswordComponent } from './auth/Forgot-password/forgot-password/forgot-password.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'refresh',
    component: RefreshComponent,
  },
  {
    path: 'vehicles',
    component: VehicleDetailsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'active-issues',
    component: ThingstodoComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'service-reminder',
    component: ServiceRemindersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'hotkeys',
    component: HotkeyPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'technical',
  //   component: TechnicalComponent,
  //   pathMatch: 'full',
  //   canActivate: [AuthGuard],
  // },
  // { path: 'table', component: TableComponent, pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },

  // { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
