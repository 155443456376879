import { Component, OnInit } from '@angular/core';
import { HotkeysService } from 'src/app/_services/hotkeys.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-hotkey',
  templateUrl: './create-hotkey.component.html',
  styleUrls: ['./create-hotkey.component.scss'],
})
export class CreateHotkeyComponent implements OnInit {
  loading: boolean = false;
  hotkeyForm = this.fb.group({
    hotkey_main: ['' , Validators.required],
    hotkey_tag: ['', Validators.required],
    main_description: ['', Validators.required],
    tag_description: ['', Validators.required],
  });

  constructor(
    private hotkeysService: HotkeysService,
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.hotkeyForm = this.fb.group({
      hotkey_main: ['', Validators.required],
      hotkey_tag: ['', Validators.required],
      main_description: ['', Validators.required],
      tag_description: ['', Validators.required],
    });
  }

  createHotkey() {
    this.loading = true;

    if (this.hotkeyForm.invalid) {
      this.loading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all the required fields',
      });
      return;
    }

    this.hotkeysService.addHotkey(this.hotkeyForm.value).subscribe(
      (data) => {
        this.hotkeysService.getHotkeys().subscribe((data) => {
          this.hotkeysService.updateHotkeys(data);
        });
      },
      (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while creating the hotkey',
        });
      },
      () => {
        this.loading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Hotkey created successfully',
        });
        this.ref.close();
      }
    );
  }
}
