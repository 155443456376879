import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() data: any;
  @Input() dataConfig: any;
  @Output() selectedRowObservable$ = new EventEmitter<any>();
  @Output() selectedRowCMObservable$: EventEmitter<any> =
    new EventEmitter<any>();
  selectedRow: any;
  menuItems: MenuItem[];
  selectedRowCM: any;

  constructor() {
    this.menuItems = [
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          if (this.selectedRowCM) {
            this.selectedRowCMObservable$.emit(this.selectedRowCM);
          }
        },
      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: (event) => {
          if (this.selectedRowCM) {
          }
        },
      },
    ];
  }

  ngOnInit(): void {
    console.log('data', this.data);
    console.log('dataConfig', this.dataConfig);
  }

  onRowSelect($event: any) {
    this.selectedRow = $event.data;
    this.selectedRowObservable$.emit($event.data);
    console.log('selectedRow', $event);
  }

  onContextMenu($event: Event) {
    throw new Error('Method not implemented.');
  }
}
