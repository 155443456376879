<form [formGroup]="serviceForm" (ngSubmit)="createReminder()">
  <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
    <div class="grid formgrid p-fluid">
      
      <div class="field mb-4 col-12 lg:col-6">
        <label for="serviceReminder">Vehicle Reference</label>
        <p-dropdown
          placeholder="Select a Vehicle"
          formControlName="vehicle_reference"
          optionLabel="vehicle_reference"
          [options]="vehicleList"
          optionValue="vehicle_reference"
          appendTo="body"
        ></p-dropdown>
        <div
          *ngIf="
            serviceForm.get('vehicle_reference')?.invalid &&
            (serviceForm.get('vehicle_reference')?.dirty ||
              serviceForm.get('vehicle_reference')?.touched)
          "
        >
          <small class="text-red-500">Vehicle Reference is required</small>
        </div>
      </div>
      <div class="field mb-4 col-12 lg:col-6">
        <label for="serviceReminder">KM Reading</label>
        <input
          pInputText
          inputId="minmax-buttons"
          id="serviceReminder"
          name="serviceReminder"
          class="w-full"
          formControlName="km_reading"
        />
        <div
          *ngIf="
            serviceForm.get('km_reading')?.invalid &&
            (serviceForm.get('km_reading')?.dirty ||
              serviceForm.get('km_reading')?.touched)
          "
        >
          <small class="text-red-500">KM Reading is required</small>
      </div>
      </div>
      <div class="flex flex-row col-12 m-0 p-0">
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Save"
            class="bg-bluegray-700 text-white mr-2"
            [disabled]="loading"
            [loading]="loading"
            type="submit"
          ></button>
        </div>
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Cancel"
            class="bg-bluegray-700 text-white"
            type="button"
            [disabled]="loading"
            [loading]="loading"
            (click)="ref.close()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
