<div class="grid">
  <div class="surface-card shadow-2 col-6 mr-2">
    <div class="flex flex-row font-semibold">
      <h3><span class="pi pi-list mr-2"></span>Vehicle Details</h3>
    </div>
  </div>
  <div class="surface-card shadow-2 col-6">
    <div class="flex font-semibold">
      <h3><span class="pi pi-list mr-2"></span>Service Details</h3>
    </div>
  </div>
</div>
