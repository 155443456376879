import { Component, OnDestroy, OnInit } from '@angular/core';

import { VehiclesService } from 'src/app/_services/vehicles.service';
import { Vehicle } from 'src/app/_models/vehicle';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StatisticsService } from 'src/app/_services/statistics.service';
import { MostRecentChangesService } from 'src/app/_services/most-recent-changes.service';
import { ActiveIssues } from 'src/app/_models/active-issues';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  statisticsVehiclesQuery$: Subscription | undefined;

  statisticsVehicles$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  loadingVehicles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  statisticsActiveIssuesQuery$: Subscription | undefined;
  statisticsActiveIssues$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  loadingActiveIssues$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  mostRecentChangesActiveIssuesQuery$: Subscription | undefined;
  mostRecentChangesActiveIssues$: BehaviorSubject<ActiveIssues[]> =
    new BehaviorSubject<ActiveIssues[]>([]);

  constructor(
    public mostRecentChangesService: MostRecentChangesService,
    public vehiclesService: VehiclesService,
    public statisticsService: StatisticsService
  ) {}

  ngOnInit(): void {
    this.vehiclesService.getAll();

    this.statisticsVehiclesQuery$ = this.statisticsService
      .getVehicleStatistics()
      .subscribe((res: any) => {
        this.statisticsVehicles$.next(res);
        this.loadingVehicles$.next(false);
      });

    this.statisticsActiveIssuesQuery$ = this.statisticsService
      .getActiveIssueStatistics()
      .subscribe((res: any) => {
        this.statisticsActiveIssues$.next(res);
        this.loadingActiveIssues$.next(false);
      });

    this.mostRecentChangesActiveIssuesQuery$ = this.mostRecentChangesService
      .getMostRecentChangesActiveIssues()
      .subscribe((res: any) => {
        console.log(res);
        this.mostRecentChangesActiveIssues$.next(res);
      });
  }

  ngOnDestroy(): void {
    if (this.statisticsVehiclesQuery$) {
      this.statisticsVehiclesQuery$.unsubscribe();
    }
    if (this.statisticsActiveIssuesQuery$) {
      this.statisticsActiveIssuesQuery$.unsubscribe();
    }

    if (this.mostRecentChangesActiveIssuesQuery$) {
      this.mostRecentChangesActiveIssuesQuery$.unsubscribe();
    }
  }
}
