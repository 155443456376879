import { Component, HostListener, Input, NgModule, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../_services/authentication.service';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  profileitems: MenuItem[];
  @Input() user: any = this.authenticationService.userValue;
  user$ = this.authenticationService.user;
  sideMenuItems: MenuItem[] = [];
  sidebarVisible: boolean = true;

  visibleSidebar = false;
  isMobile = false;
  menuItems = [
    { label: 'Home', routerLink: '/home' },
    { label: 'About', routerLink: '/about' },
  ];

  showFiller = false;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    public authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.userValue;

    if (this.user) {
      this.sideMenuItems = [
        { label: 'Home', routerLink: '/', icon: 'pi pi-fw pi-home' },
        {
          label: 'Vehicles',
          routerLink: 'vehicles',
          icon: 'pi pi-fw pi-truck',
        },
        {
          label: 'Technical',
          routerLink: 'technical',
          icon: 'pi pi-fw pi-cog',
        },
        {
          label: 'Active Issues',
          routerLink: 'active-issues',
          icon: 'pi pi-fw pi-list',
        },
        {
          label: 'Hotkeys',
          routerLink: 'hotkeys',
          icon: 'pi pi-fw pi-key',
        }
      ];
    }

    this.profileitems = [
      {
        label: 'Logout',
        command: () => {
          this.authenticationService.logout();
        },
      },
    ];
  }
  ngOnInit() {
    this.checkScreenWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    this.isMobile = window.innerWidth <= 768;
  }

  toggleSidebar() {
    this.visibleSidebar = !this.visibleSidebar;
  }

  hideSidebar() {
    this.visibleSidebar = false;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(1)
    );

}
