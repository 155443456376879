import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { HotkeysService } from 'src/app/_services/hotkeys.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateHotkeyComponent } from './create-hotkey/create-hotkey.component';
import { UpdateHotkeyComponent } from './update-hotkey/update-hotkey.component';
import { CreateTagDialogComponent } from './create-tag-dialog/create-tag-dialog.component';
import { UpdateTagDialogComponent } from './update-tag-dialog/update-tag-dialog.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-hotkeys',
  templateUrl: './hotkeys.component.html',
  styleUrls: ['./hotkeys.component.scss'],
})
export class HotkeysComponent implements OnInit {
  hotkeys: any = [];
  ref: any;
  selectedKey: any;
  hotkey: any;
  hotkey$ = this.hotkeysService.hotkeys$;
  loadingHotkey$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  activeIssuesTransformed: any[] = [];
  @ViewChild('dt') dt: Table | undefined;

  columns = [
    { field: 'hotkey_main', header: 'Main Hotkey' },
    { field: 'main_description', header: 'Main description' },
    { field: 'hotkey_tag', header: 'Hotkey Tag' },
  ];

  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => this.updateHotkey(this.selectedKey),
    },
    {
      label: 'Delete Hotkey',
      icon: 'pi pi-trash',
      command: () => this.deleteHotkey(this.selectedKey),
    },
    {
      label: 'Add Tag',
      icon: 'pi pi-plus',
      command: () => this.showCreateTagDialog(this.selectedKey),
    }
  ];

  constructor(
    private hotkeysService: HotkeysService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.hotkeysService.getHotkeys().subscribe((data) => {
      this.hotkeysService.updateHotkeys(data);
    });
    setTimeout(() => {
      this.loadingHotkey$.next(false);
    }, 1000);
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  showCreateTagDialog(hotkey: any) {
    this.ref = this.dialogService.open(CreateTagDialogComponent, {
      width: '70%',
      data: hotkey,
      showHeader: false,
    });
  }

  showUpdateTagDialog(tag: any) {
    this.ref = this.dialogService.open(UpdateTagDialogComponent, {
      width: '70%',
      data: tag,
      showHeader: false,
    });
  }

  showCreateHotkeyDialog() {
    this.ref = this.dialogService.open(CreateHotkeyComponent, {
      width: '70%',
      showHeader: false,
    });
  }

  updateHotkey(hotkey: any) {
    this.ref = this.dialogService.open(UpdateHotkeyComponent, {
      width: '70%',
      showHeader: false,
      data: {
        hotkey: hotkey,
      },
    });
  }

  deleteTag(tag: any){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this tag?',
      accept: () => {
        this.hotkeysService.deleteHotkey(tag.id).subscribe(
          (data) => {
            this.hotkeysService.getHotkeys().subscribe((data) => {
              this.hotkeysService.updateHotkeys(data);
            });
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while deleting the tag',
            });
          },
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Tag deleted successfully',
            });
          }
        );
      },
    });
  }

  deleteHotkey(hotkey: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this hotkey? This will also delete all associated tags',
      accept: () => {
        // loop through hotkey$ and delete all tags 
        hotkey.descriptions.forEach((tag: any) => {
          this.hotkeysService.deleteHotkey(tag.id).subscribe(
            (data) => {
              this.hotkeysService.getHotkeys().subscribe((data) => {
                this.hotkeysService.updateHotkeys(data);
              });
            }
          );
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Hotkey deleted successfully',
        });
      },
    });
  }

  storeRowDetail(rowData: any) {
    this.selectedKey = rowData;
  }


}
