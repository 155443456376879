import { Component, OnInit } from '@angular/core';
import { IssuesService } from 'src/app/_services/issues.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-active-issue-report-dialog',
  templateUrl: './active-issue-report-dialog.component.html',
  styleUrls: ['./active-issue-report-dialog.component.scss']
})
export class ActiveIssueReportDialogComponent implements OnInit {
  loading: boolean = false;
  form = this.fb.group({
    email: ['', Validators.required]
  })
  
  constructor(
    public issuesService: IssuesService,
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
  }
  
  generate_excel_report() {
    this.issuesService.getIssueReport()
    setTimeout(() => {
      this.ref.close();
    }, 3000);
  }

  generate_csv_report() {
    this.issuesService.getCsvReport()
  }

  onSubmit() {
    this.loading = true;
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    
    this.issuesService.sendIssueReport(this.form.value.email).subscribe(() => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.messageService.add({severity:'error', summary:'Error', detail:'Failed to send report'});
      this.ref.close();
    }, () => {
      this.loading = false;
      this.messageService.add({severity:'success', summary:'Success', detail:'Report sent successfully'});
      this.ref.close();
    });
  }

}
