<div *ngIf="editFormGroups.length > 0; else showOne">
  <div *ngFor="let desc of editFormData; let i = index">
    <form [formGroup]="editFormGroups[i]">
      <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-12 lg:col-4">
            <label for="vehicleId">Vehicle Reference Number</label>
            <p-dropdown
              placeholder="Select a Vehicle"
              formControlName="vehicle_reference"
              optionLabel="vehicle_reference"
              [options]="vehicleList"
              optionValue="vehicle_reference"
            ></p-dropdown>
          </div>
          <div class="field mb-4 col-12 lg:col-8">
            <label for="issue">Description</label>
            <p-autoComplete
              formControlName="description"
              [suggestions]="suggestedDescriptions"
              (completeMethod)="filterDescriptions($event)"
              (onSelect)="onDescriptionSelect($event)"
              field="value"
              [dropdown]="true"
              [minLength]="1"
              [autoHighlight]="true"
              [multiple]="true"
              [scrollHeight]="'200px'"
              [placeholder]="'Issue'"
            >
              <ng-template let-issue pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ issue.label }}</div>
                </div>
              </ng-template>
            </p-autoComplete>
            <div
              class="px-1 py-1"
              *ngIf="
                editFormGroups[i].get('description')?.invalid &&
                editFormGroups[i].get('description')?.touched
              "
            >
              <small id="first_name-help" class="p-error block"
                ><strong>Description</strong> is required</small
              >
            </div>
          </div>
          <div class="field mb-4 col-12 lg:col-12">
            <label for="comment">Remarks</label>
            <textarea
              pInputTextarea
              rows="5"
              cols="30"
              formControlName="remarks"
            ></textarea>
            
            <div
              class="px-1 py-1"
              *ngIf="
              editFormGroups[i].get('remarks')?.invalid &&
              (editFormGroups[i].get('remarks')?.touched ||
              editFormGroups[i].get('remarks')?.dirty)
            "
            >
              <small id="first_name-help" class="p-error block"
                ><strong>Remarks</strong> are required</small
              >
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div
    class="grid formgrid p-fluid flex align-items-center justify-content-center m-2"
  >
    <div class="flex flex-row col-12 m-0 p-0">
      <div class="col-4">
        <button
          pButton
          pRipple
          label="Save"
          class="bg-bluegray-700 text-white mr-2"
          [disabled]="loading"
          [loading]="loading"
          (click)="onActiveIssueFormSubmit()"
          type="submit"
        ></button>
      </div>
      <div class="col-4">
        <button
          pButton
          pRipple
          label="Cancel"
          class="bg-bluegray-700 text-white"
          type="button"
          [disabled]="loading"
          [loading]="loading"
          (click)="ref.close()"
        ></button>
      </div>
    </div>
  </div>
</div>

<ng-template #showOne>
  <form [formGroup]="activeIssueForm" (ngSubmit)="onActiveIssueFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-4">
          <label for="vehicleId">Vehicle Reference Number</label>
          <p-dropdown
            placeholder="Select a Vehicle"
            formControlName="vehicle_reference"
            optionLabel="vehicle_reference"
            [options]="vehicleList"
            optionValue="vehicle_reference"
          ></p-dropdown>
          <div
            class="px-1 py-1"
            *ngIf="
              activeIssueForm.get('vehicle_reference')?.invalid &&
              (activeIssueForm.get('vehicle_reference')?.touched ||
                activeIssueForm.get('vehicle_reference')?.dirty)
            "
          >
            <small id="first_name-help" class="p-error block"
              ><strong>Vehicle Reference</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-8">
          <label for="issue">Description</label>

          <p-autoComplete
            formControlName="description"
            [suggestions]="suggestedDescriptions"
            (completeMethod)="filterDescriptions($event)"
            (onSelect)="onDescriptionSelect($event)"
            field="label"
            [dropdown]="true"
            [minLength]="1"
            [autoHighlight]="true"
            [multiple]="true"
            [scrollHeight]="'200px'"
            [placeholder]="'Issue'"
          ></p-autoComplete>
          <div
            class="px-1 py-1"
            *ngIf="
              activeIssueForm.get('description')?.invalid &&
              (activeIssueForm.get('description')?.touched ||
                activeIssueForm.get('description')?.dirty)
            "
          >
            <small id="first_name-help" class="p-error block"
              ><strong>Description</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-12">
          <label for="comment">Remarks</label>
          <textarea
            pInputTextarea
            rows="5"
            cols="30"
            formControlName="remarks"
            [value]="''"
          ></textarea>
          <div
            class="px-1 py-1"
            *ngIf="
              activeIssueForm.get('remarks')?.invalid &&
              (activeIssueForm.get('remarks')?.touched ||
                activeIssueForm.get('remarks')?.dirty)
            "
          >
            <small id="first_name-help" class="p-error block"
              ><strong>Remarks</strong> are required</small
            >
          </div>
        </div>
        <div class="flex flex-row col-12 m-0 p-0">
          <div class="col-4">
            <button
              pButton
              pRipple
              label="Save"
              class="bg-bluegray-700 text-white mr-2"
              [disabled]="loading"
              [loading]="loading"
              type="submit"
            ></button>
          </div>
          <div class="col-4">
            <button
              pButton
              pRipple
              label="Cancel"
              class="bg-bluegray-700 text-white"
              type="button"
              [disabled]="loading"
              [loading]="loading"
              (click)="ref.close()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
