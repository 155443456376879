<form [formGroup]="hotkeyForm" (ngSubmit)="createHotkey()">
  <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
    <div class="grid formgrid p-fluid">
      <div class="col-12">
        <h2 class="text-xl font-semibold">
          Please specify a new main hotkey and provide a description
        </h2>
      </div>
      <div class="field mb-4 col-12 lg:col-6">
        <label for="hotkey">Main Hotkey</label>
        <input
          pInputText
          type="text"
          id="hotkey"
          name="hotkey"
          class="w-full"
          formControlName="hotkey_main"
        />
        <div
          *ngIf="
            hotkeyForm.get('hotkey_main')?.invalid &&
            (hotkeyForm.get('hotkey_main')?.touched ||
              hotkeyForm.get('hotkey_main')?.dirty)
          "
          class="px-1 py-1"
        >
          <small class="p-error block"
            ><strong>A main hotkey</strong> is required</small
          >
        </div>
      </div>
      <div class="field mb-4 col-12 lg:col-6">
        <label for="hotkey">Hotkey Description</label>
        <input
          pInputText
          type="text"
          id="hotkey"
          name="hotkey"
          class="w-full"
          formControlName="main_description"
        />
        <div
          *ngIf="
            hotkeyForm.get('main_description')?.invalid &&
            (hotkeyForm.get('main_description')?.touched ||
              hotkeyForm.get('main_description')?.dirty)
          "
          class="px-1 py-1"
        >
          <small class="p-error block"
            ><strong>A hotkey description</strong> is required</small
          >
        </div>
      </div>
      <div class="flex flex-row col-12 m-0 p-0">
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Save"
            class="bg-bluegray-700 text-white mr-2"
            [disabled]="loading"
            [loading]="loading"
            type="submit"
          ></button>
        </div>
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Cancel"
            class="bg-bluegray-700 text-white"
            type="button"
            [disabled]="loading"
            [loading]="loading"
            (click)="ref.close()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
