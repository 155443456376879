<div>
  <h1>Remarks</h1>
</div>
<div class="flex align-content-center bg-bluegray-800 border-round-lg py-1 my-3" *ngFor="let issue of dialogData.descriptions">
  <div *ngIf="issue.remarks" class="m-5 ">
    <span class="text-2xl font-medium text-bluegray-100">
     {{ issue.description }} - {{ issue.remarks }}<br />
    </span>
  </div>
</div>
