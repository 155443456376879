import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { TechnicalViewService } from 'src/app/_services/technical-view.service';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { FormComponent } from '../../components/form/form.component';
import { CreateVehicleComponent } from '../create-vehicle/create-vehicle.component';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class VehicleDetailsComponent implements OnInit {
  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  Vehicles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  vehicles: any[] = [];
  selectedVehicle: any;
  vehicles$ = this.vehiclesService.vehiclesObservable;
  @ViewChild('dt') dt: Table | undefined;

  constructor(
    public dialogService: DialogService,
    public vehiclesService: VehiclesService,
    public technicalViewService: TechnicalViewService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.vehiclesService.getVehicles().subscribe((data) => {
      this.vehiclesService.updateVehicles(data);
    });
    setTimeout(() => {
      this.Vehicles$.next(false);
    }, 1000);
    this.getVehiclesOptionsList();
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getVehiclesOptionsList() {
    return this.vehiclesService.getVehicles().subscribe((vehicles: any[]) => {
      this.vehicles = vehicles;
    });
  }

  // ---------------------------- Table actions ---------------------------- //
  columns = [
    { field: 'vehicle_reference', header: 'Vehicle Reference ID' },
    { field: 'engine_no', header: 'Vehicle Engine Number' },
    { field: 'chassis_no', header: 'Vehicle Chasis Number' },
    { field: 'model', header: 'Vehicle Model' },
    { field: 'year_model', header: 'Year Model' },
    { field: 'date_inservice', header: 'Vehicle Date In Service' },
    { field: 'service_interval', header: 'Vehicle Service Interval' },
  ];

  storeRowDetail(rowData: any) {
    this.selectedVehicle = rowData;
  }

  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => this.showEdit(this.selectedVehicle),
    },
  ];

  showEdit($event: any) {
    console.log('row', $event);
    let row = $event;
    this.ref = this.dialogService.open(FormComponent, {
      data: {
        formdata: row,
      },
      header: 'Edit Vehicle',
      width: '65%',
      height: '65%',
      closeOnEscape: true,
    });
  }

  showAdd($event: any) {
    let vehicle = $event;
    this.ref = this.dialogService.open(CreateVehicleComponent, {
      data: {
        vehicleData: vehicle,
      },
      header: 'Add Vehicle',
      width: '65%',
      height: '65%',
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) { // Check if result is not null or undefined
        this.vehiclesService.getVehicles().subscribe(() => {
          this.getVehiclesOptionsList();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vehicle successfully added' });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to add vehicle' });
        });
      }
    });
  }

  // Edit function to open the form dialog yet to do
  editVehicle(rowData: any) {
    console.log('row', rowData);
    this.ref = this.dialogService.open(CreateVehicleComponent, {
      data: {
        formdata: rowData,
      },
      header: 'Edit Vehicle',
      width: '60%',
      height: '60%',
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) { // Check if result is not null or undefined
        this.vehiclesService.getVehicles().subscribe(() => {
          this.getVehiclesOptionsList();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vehicle updated successfully' });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update vehicle' });
        });
      }
    });
  }

  // Delete function
  // deleteVehicle(rowData: any) {
  //   this.vehiclesService.deleteVehicle(rowData.id).subscribe(() => {
  //     this.getVehiclesOptionsList();
  //   });
  // }
  deleteVehicle(rowData: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this vehicle?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.vehiclesService.deleteVehicle(rowData.id).subscribe(() => {
          this.getVehiclesOptionsList();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vehicle deleted successfully' });
        });
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'Vehicle deletion cancelled' });
      }
    });
  }
}

