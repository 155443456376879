import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IssuesService } from 'src/app/_services/issues.service';
import { TechnicalViewService } from 'src/app/_services/technical-view.service';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { ActiveIssueFormComponent } from '../active-issue-form/active-issue-form.component';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { SignoffIssueComponent } from '../signoff-issue/signoff-issue.component';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActiveIssueReportDialogComponent } from '../active-issue-report-dialog/active-issue-report-dialog.component';
import { Table } from 'primeng/table';
import { CreateServiceReminderComponent } from '../../service-reminder/create-service-reminder/create-service-reminder.component';

@Component({
  selector: 'app-active-issue-table',
  templateUrl: './active-issue-table.component.html',
  styleUrls: ['./active-issue-table.component.scss'],
})
export class ActiveIssueTableComponent implements OnInit {
  @ViewChild('dt') dt: Table | undefined;
  @ViewChild('signedOffIssuesTable') signedOffIssuesTable: Table | undefined;
  clearActiveIssueSelection() {
    this.selectedDescription = [];
  }
  dateTimeNow: string;
  items = [
    {
      icon: 'pi pi-pencil',
      command: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Add',
          detail: 'Data Added',
        });
      },
    },
    {
      icon: 'pi pi-refresh',
      command: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Update',
          detail: 'Data Updated',
        });
      },
    },
    {
      icon: 'pi pi-trash',
      command: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Delete',
          detail: 'Data Deleted',
        });
      },
    },
    {
      icon: 'pi pi-upload',
      routerLink: ['/fileupload'],
    },
    {
      icon: 'pi pi-external-link',
      url: 'http://angular.io',
    },
  ];
  activeIssues: any[] = [];
  ref: DynamicDialogRef | undefined;
  selectedRow: any;
  descriptionList: any[] = [];
  htmlToAdd: any;
  clicked: boolean = false;
  activeIssuesTransformed: any[] = [];
  selectedDescription: any[] = [];
  sortedIssues: any[] = [];
  position: string = 'center';
  visible: boolean = false;

  products: any[] = [];
  loadingSignOff$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  constructor(
    public dialogService: DialogService,
    public vehiclesService: VehiclesService,
    public technicalViewService: TechnicalViewService,
    public issuesService: IssuesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router
  ) {
    this.dateTimeNow = new Date().toISOString();
  }

  ngOnInit(): void {
    this.dateTimeNow = new Date().toISOString();

    this.issuesService.activeIssuesObservable.subscribe((res: any) => {
      this.activeIssues = res;
      this.activeIssuesTransformed = this.transformData(this.activeIssues);
      this.sortedIssues = res.sort((a: any, b: any) => {
        if (a.vehicle_reference < b.vehicle_reference) return -1;
        if (a.vehicle_reference > b.vehicle_reference) return 1;

        if (a.date_signed_off < b.date_signed_off) return -1;
        if (a.date_signed_off > b.date_signed_off) return 1;

        return 0;
      });
      // Simulate loading completion
      setTimeout(() => {
        this.loadingSignOff$.next(false);
      }, 1000); // Adjust the simulated loading time as needed

      console.log(this.sortedIssues);
    });
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
    this.signedOffIssuesTable?.filterGlobal(inputElement.value, 'contains');
  }

  //TODO: Figure out a way to get rid of description tags without deleting them from the database
  deleteDescription(description: any): void {
    //for multi-select feature
    description.fine_amount = 1;
    this.issuesService.updateActiveIssue(description.id, description);
  }

  handleCellClick(event: Event): void {
    event.stopPropagation();
  }

  deleteMultipleDescriptions(): void {
    this.selectedDescription.forEach((description) => {
      this.deleteDescription(description);
    });
  }

  // ---------------------------- Table actions ---------------------------- //

  item: MenuItem[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.showEditActiveIssueModal(this.selectedRow);
          },
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            this.confirmDelete();
          },
        },
      ],
    },
  ];

  columns = [
    { field: 'vehicle_reference', header: 'Vehicle Reference' },
    { field: 'description', header: 'Description' },
  ];

  signedOffColumns = [
    { field: 'vehicle_reference', header: 'Vehicle Reference' },
    { field: 'description', header: 'Description' },
    { field: 'fine_amount', header: 'Fine Amount' },
    { field: 'signoff_type', header: 'Signoff Type' },
    { field: 'date_signed_off', header: 'Date Signed Off' },
  ];

  transformData(issues: any): any[] {
    const mergedIssues: any[] = [];

    issues.forEach((issue: any) => {
      const existingIssue = mergedIssues.find(
        (i) => i.vehicle_reference === issue.vehicle_reference
      );
      if (!issue.signed_off && !issue.fine_amount) {
        if (existingIssue) {
          // Add a new description to the existing row
          existingIssue.descriptions.push({
            id: issue.id,
            description: issue.description,
            vehicle_reference: issue.vehicle_reference,
            remarks: issue.remarks,
          });
        } else {
          // Add a new entry with a new array of descriptions
          mergedIssues.push({
            ...issue,
            descriptions: [
              {
                id: issue.id,
                description: issue.description,
                vehicle_reference: issue.vehicle_reference,
                remarks: issue.remarks,
              },
            ],
          });
        }
      }
    });
    return mergedIssues;
  }

  // ---------------------------- Show dialog ---------------------------- //

  showSignOffModal(activeIssue: any, description: any) {
    this.ref = this.dialogService.open(SignoffIssueComponent, {
      data: {
        data: activeIssue,
        descriptionData: description,
      },
      header: 'Sign Off Issue',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      this.clearActiveIssueSelection();
    });
  }

  showCreateServiceReminder() {
    this.ref = this.dialogService.open(CreateServiceReminderComponent, {
      header: 'Create Service Reminder',
      width: '70%',
    });
  }

  showAddActiveIssueModal() {
    this.ref = this.dialogService.open(ActiveIssueFormComponent, {
      data: {
        formdata: null,
        listOfVehicles: this.vehiclesService.getVehicles(),
      },
      header: 'Create a new Active Issue',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  showEditActiveIssueModal(activeIssue: any) {
    var temp = [];
    //was my issue && activeIssue.length > 1
    if (Array.isArray(activeIssue)) {
      // If activeIssue is already an array, use it as is.
      temp = activeIssue;
    } else {
      // Otherwise, wrap it in an array.
      temp.push(activeIssue);
    }
    this.ref = this.dialogService.open(ActiveIssueFormComponent, {
      data: {
        formdata: temp,
        listOfVehicles: this.vehiclesService.getVehicles(),
      },
      header: 'Edit Active Issue',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  showNotesDialog(rowData: any) {
    this.ref = this.dialogService.open(NoteDialogComponent, {
      data: {
        formdata: rowData,
        listOfVehicles: this.vehiclesService.getVehicles(),
      },
      header: 'Notes',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  confirmDelete(description: any = null) {
    console.log(this.selectedDescription);

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this issue?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.deleteDescription(description);
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Record deleted',
        });
      },
    });
  }

  confirmMultiDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete these issues?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.deleteMultipleDescriptions();
        this.selectedDescription = [];
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Records deleted',
        });
      },
    });
  }

  showReportDialog() {
    this.ref = this.dialogService.open(ActiveIssueReportDialogComponent, {
      header: 'Reporting',
      width: '60%',
      height: '35%',
      closeOnEscape: true,
    });
  }

  generate_excel_report() {
    this.issuesService.getIssueReport()
  }

  generate_csv_report() {
    this.issuesService.getCsvReport()
  }

  showDialog(position: string) {
    this.position = position;
    this.visible = true;
  }
}
