import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceRecordsService {
  serviceRecords: any[] = [];
  serviceRecords$ = new Subject<any[]>();

  constructor(private api: ApiService) {}

  getServiceRecords() {
    this.api.getAPI<any>('servicerecords').subscribe((res) => {
      console.log(res);
      this.serviceRecords = res.body.results;
      this.serviceRecords$.next(this.serviceRecords);
    });
  }

  getServiceRecordsforVehicle(vehicleId: string) {
    this.api
      .getAPI<any>('servicerecords?vehicle_ID=' + vehicleId)
      .subscribe((res) => {
        console.log(res);
        this.serviceRecords = res.body;
        this.serviceRecords$.next(this.serviceRecords);
      });
  }
}
