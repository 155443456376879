<form [formGroup]="tagForm" (ngSubmit)="updateTag()">
  <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
    <div class="grid formgrid p-fluid">
      <div class="col-12">
        <h2 class="text-xl font-semibold">
          Edit Tag
        </h2>
      </div>
      <div class="field mb-4 col-12 lg:col-6">
        <label for="hotkey">Tag</label>
        <input
          pInputText
          type="text"
          id="hotkey"
          name="hotkey"
          class="w-full"
          formControlName="hotkey_tag"
        />
        <div
        *ngIf="
          tagForm.get('hotkey_tag')?.invalid &&
          (tagForm.get('hotkey_tag')?.touched ||
          tagForm.get('hotkey_tag')?.dirty)
        "
        class="px-1 py-1"
      >
        <small class="p-error block"
          ><strong>A tag</strong> is required</small
        >
      </div>
      </div>
      <div class="field mb-4 col-12 lg:col-6">
        <label for="hotkey">Tag Description</label>
        <input
          pInputText
          type="text"
          id="hotkey"
          name="hotkey"
          class="w-full"
          formControlName="tag_description"
        />
        <div
        *ngIf="
          tagForm.get('tag_description')?.invalid &&
          (tagForm.get('tag_description')?.touched ||
          tagForm.get('tag_description')?.dirty)
        "
        class="px-1 py-1"
      >
        <small class="p-error block"
          ><strong>A tag description</strong> is required</small
        >
      </div>
      </div>
      <div class="flex flex-row col-12 m-0 p-0">
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Update Hotkey"
            class="bg-bluegray-700 text-white"
            [disabled]="loading"
            [loading]="loading"
            type="submit"
          ></button>
        </div>
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Cancel"
            class="bg-bluegray-700 text-white"
            type="button"
            [disabled]="loading"
            [loading]="loading"
            (click)="ref.close()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
