<div class="navigation-shell">
  <p-sidebar
    [(visible)]="visibleSidebar"
    [baseZIndex]="10000"
    [modal]="true"
    [fullScreen]="false"
    [blockScroll]="true"
  >
    <!-- mobile menu -->
    <div class="sidebar-content">
      <div
        class="flex align-items-center justify-content-center px-5  text-bluegray-800 flex-shrink-0 text-lg"
        style="height: 60px"
      >
        <span>VTT</span>
      </div>
      <hr class="mx-3 border-top-1 border-none surface-border">
      <ul *ngIf="user$ | async" class="list-none p-0 m-0">
        <li 
        class="mt-2"
        *ngFor="let menuItem of sideMenuItems">
          <a
            pRipple
            class="flex align-items-center justify-content-center cursor-pointer p-3  hover:bg-bluegray-700 hover:border-round border-round text-bluegray-600 hover:text-bluegray-50 transition-duration-150 transition-colors"
            [routerLinkActive]="
              'bg-bluegray-900 text-white border-round'
            "
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="[menuItem.routerLink]"
            (click)="hideSidebar()"
            style="text-decoration: none"
          >
            <i
              class="pi {{ menuItem.icon }} mr-2 lg:mr-0 text-base lg:text-2xl "
            ></i>
            <span class="font-medium inline text-base lg:text-xs lg:hidden">{{
              menuItem.label
            }}</span>
          </a>
        </li>
      </ul>
      <div class="mt-auto ">
        <hr class="mx-3 border-top-1 border-none surface-border" />
        <a
          *ngIf="user$ | async"
          pRipple
          class="flex align-items-center justify-content-center my-3 flex cursor-pointer p-3 hover:bg-pink-800 border-round transition-duration-150 transition-colors"
          (click)="this.authenticationService.logout()"
          pStyleClass="#app-sidebar-2"
          enterClass="hidden"
          enterActiveClass="fadeinleft"
          leaveToClass="hidden"
          leaveActiveClass="fadeoutleft"
        >
          <i
            class="pi pi-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"
          ></i>
          <span class="font-medium inline lg:hidden text-pink-500 ">Sign Out</span>
        </a>
      </div>
    </div>
  </p-sidebar>

  <!-- large screen menu -->
  <div class="static-sidebar" *ngIf="!isMobile" class="bg-bluegray-800">
    <div class="sidebar-content">
      <div
        class="flex align-items-center justify-content-center px-5 bg-bluegray-800 text-bluegray-100 flex-shrink-0 text-lg"
        style="height: 60px"
      >
        <span>VTT</span>
      </div>
      <ul
      *ngIf="user$ | async"
      class="list-none p-0 m-0">
        <li
        class="mt-2"
          [routerLinkActive]="'bg-bluegray-900 text-bluegray-100 border-round'"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngFor="let menuItem of sideMenuItems"
        >
          <a
            pRipple
            class="flex align-items-center justify-content-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 transition-duration-150 transition-colors"
            [routerLink]="[menuItem.routerLink]"
            (click)="hideSidebar()"
            style="text-decoration: none"
          >
            <i
              class="pi {{
                menuItem.icon
              }} lg:mr-0 text-base lg:text-2xl align-self-center"
            ></i>
          </a>
        </li>
      </ul>
      <div class="mt-auto">
        <hr class="mx-3 border-top-1 border-none surface-border" />
        <a
          *ngIf="user$ | async"
          pRipple
          class="flex align-items-center justify-content-center my-3 flex cursor-pointer p-3 hover:bg-pink-800 border-round text-bluegray-100 hover:text-bluegray-50 transition-duration-150 transition-colors"
          (click)="this.authenticationService.logout()"
          pStyleClass="#app-sidebar-2"
          enterClass="hidden"
          enterActiveClass="fadeinleft"
          leaveToClass="hidden"
          leaveActiveClass="fadeoutleft"
        >
          <i
            class="pi pi-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"
          ></i>
          <span class="font-medium inline lg:hidden">Sign Out</span>
        </a>
      </div>
    </div>
  </div>

  <div class="content-wrapper">
    <div class="mobile-top-bar" *ngIf="isMobile && (user$ | async)" class="bg-bluegray-900">
      <button
        type="button"
        class="bg-bluegray-900 text-bluegray-100 p-3"
        pButton 
        icon="pi pi-bars"
        (click)="toggleSidebar()"
      ></button>
    </div>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
 