import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MostRecentChangesService {
  constructor(private api: ApiService) {}

  changes: any[] = [];
  changes$: Subject<any> = new Subject<any>();

  getMostRecentChangesActiveIssues() {
    return this.api.getAPI<any>('activeissues/mostrecentchanges').pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }

  getMostRecentChangesTechnicalRecords() {
    return this.api.getAPI<any>('technicalrecords/mostrecentchanges').pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }
}
