import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignedOffService {
  private signedSubject = new BehaviorSubject<any>(null);
  public signedObservable = this.signedSubject.asObservable();

  public signOffForm: FormGroup<{
    signoff_type: FormControl<string | null>,
    fine_amount: FormControl<string | null>,
    signed_off: FormControl<boolean | null>,
    vehicle_reference: FormControl<string | null>,
    description: FormControl<string | null>,
    remarks: FormControl<string | null>,
    date_signed_off: FormControl<string | null>,
  }>

  constructor() {
    this.signOffForm = new FormGroup({
      signoff_type: new FormControl<string | null>(null),
      fine_amount: new FormControl<string | null>(null),
      signed_off: new FormControl<boolean | null>(null),
      vehicle_reference: new FormControl<string | null>(null),
      description: new FormControl<string | null>(null),
      remarks: new FormControl<string | null>(null),
      date_signed_off: new FormControl<string | null>(null),
    });
   }

  sendData(data: any) {
    this.signedSubject.next(data);
  }
}
