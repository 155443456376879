<div class="flex flex-column flex-auto">
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-calendar text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Service Reminders</span>
        </div>
      </div>
      <div class="col-12 surface-card shadow-2 mt-4">
        <div class="w-full overflow-auto">
          <app-service-reminder></app-service-reminder>
        </div>
      </div>
    </div>
  </div>
</div>
