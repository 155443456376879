<div class="flex flex-column flex-auto">
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-truck text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Active Issues</span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center"></div>
      </div>
    </div>
    <!-- active issues stats -->
    <div class="col-12" *ngIf="false">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div
          class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
        >
          <div class="flex align-items-center mb-3">
            <i class="pi pi-exclamation-circle text-red-500 text-xl mr-2"></i>
            <span class="text-500 font-medium">Active Issues</span>
          </div>
          <span class="block text-900 font-medium mb-4 text-xl"
            >152 New (since yesterday)</span
          >
          <div class="flex align-items-center">
            <i class="pi pi-arrow-down text-green-500 text-xl mr-2"></i>
            <span class="text-green-500 font-medium">-25</span>
          </div>
        </div>

        <!-- 2nd header box -->
        <div
          class="border-bottom-1 md:border-bottom-none surface-border flex-1 p-3"
        >
          <div class="flex align-items-center mb-3">
            <i class="pi pi-shopping-cart text-orange-500 text-xl mr-2"></i>
            <span class="text-500 font-medium">Resolved Issues</span>
          </div>
          <span class="block text-900 font-medium mb-4 text-xl">$1500</span>
          <div class="flex align-items-center">
            <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
            <span class="text-green-500 font-medium">+15</span>
          </div>
        </div>
      </div>
    </div>
    <!-- active issue workspace -->
    <div class="col-12">
      <div class="surface-card shadow-2 w-full overflow-auto">
        <app-active-issue-table
          (signOffData)="signOffDataOutput($event)"
        ></app-active-issue-table>
      </div>
    </div>

    <!-- resolved issue workspace -->

    <!-- <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <p-timeline [value]="(mostRecentChangesService.changes$ | async) || []">
          <ng-template pTemplate="content" let-event>
            <small class="p-text-secondary">{{ event.date_added }}</small>
          </ng-template>
          <ng-template pTemplate="opposite" let-event>
            <p-chip [label]="event.vehicle_ID + ' - ' + event.record_type">
            </p-chip>
          </ng-template>
        </p-timeline>
      </div>
    </div>
  </div>
</div> -->
  </div>
</div>
