import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { yearModelValidator } from 'src/app/validators/yearModelValidator';

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.scss'],
})
export class CreateVehicleComponent implements OnInit {
  loading: boolean = false;
  createVehicles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  vehicleForm: FormGroup = this.formBuilder.group({
    vehicle_reference: ['', Validators.required],
    engine_no: ['', Validators.required],
    chassis_no: ['', Validators.required],
    model: ['', Validators.required],
    date_inservice: [''],
    service_interval: [1, Validators.required],
    year_model: ['', [Validators.required, yearModelValidator()]],
    active_inservice: [false, Validators.required],
    date_outservice: [''],
  });

  editFormData: any;
  fb: any;
  editFormGroups: any;
  isInEditMode: boolean | undefined;
  activeIssueForm: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public vehiclesService: VehiclesService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // Simulate loading completion
    setTimeout(() => {
      this.createVehicles$.next(false);
    }, 1000);
    this.updateFormData();
  }

  // Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data?.formdata;

    if (this.editFormData) {
      if (Array.isArray(this.editFormData)) {
        if (this.editFormData.length > 0) {
          this.editFormData.forEach((desc: any, index: number) => {
            const formGroup = this.fb.group({
              vehicle_reference: desc.vehicle_reference,
              engine_no: desc.engine_no,
              chassis_no: desc.chassis_no,
              model: desc.model,
              date_inservice: desc.date_inservice,
              service_interval: desc.service_interval,
              year_model: desc.year_model,
              active_inservice: desc.active_inservice,
              date_outservice: desc.date_outservice,
            });
            this.editFormGroups.push(formGroup);
            this.isInEditMode = true;
          });
        }
      } else {
        this.vehicleForm.patchValue(this.editFormData);
        this.isInEditMode = true;
      }
    } else {
      this.isInEditMode = false;
    }
  }

  onAddVehicleFormSubmit() {
    if (this.vehicleForm.invalid) {
      this.vehicleForm.markAllAsTouched(); //validation errors
      return; // Prevent form submission if form is invalid
    }
    if (this.isInEditMode) {
      // If in edit mode, update the existing vehicle
      const updatedFormData = this.vehicleForm.value;
      this.vehiclesService
        .updateVehicle(this.editFormData.id, updatedFormData)
        .subscribe({
          next: (res: any) => {
            console.log('Vehicle updated successfully', res);
            this.ref.close(updatedFormData); // Closing the dialog and pass the updated data
          },
          error: (err: any) => {
            console.error('Error updating vehicle', err);
          },
        });
    } else {
      // If not in edit mode, add a new vehicle
      console.log('form', this.vehicleForm.value);
      this.vehiclesService.addVehicle(this.vehicleForm.value);
      this.ref.close(this.vehicleForm.value);
    }
  }
}
