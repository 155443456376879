import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models/user';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  forgotPassword(email: string) {
    return this.http.post(
      `${environment.apiUrl}/users/forgot-password`,
      {},
      {
        params: {
          email: email,
        },
      }
    );
  }

  resetPassword(email: string, token: string, password: string) {
    return this.http.post(
      `${environment.apiUrl}/users/reset-password`,
      { new_password: password },
      {
        params: {
          reset_token: token,
          email: email,
        },
      }
    );
  }
}
