import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Vehicle } from '../_models/vehicle';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  private vehicles: Vehicle[] = [];
  private vehicles$ = new BehaviorSubject<Vehicle[]>([]);
  public vehiclesObservable = this.vehicles$.asObservable();

  constructor(
    private api: ApiService,
    private http: HttpClient // private mostRecentChangesService: MostRecentChangesService
  ) {}

  // addVehicle(newVehicle: Vehicle): Observable<any> {
  //   return this.api.postAPI<Vehicle>('vehicles', newVehicle);
  // }

  // addVehicle(newVehicle: any): void {
  //   this.api.postAPI<any>('vehicles', newVehicle).subscribe((res: any) => {
  //     this.getVehicles();
  //   });
  // }
  addVehicle(newVehicle: Vehicle): void {
    this.api.postAPI<Vehicle>('vehicles', newVehicle).subscribe({
      next: (vehicle) => {
        this.refreshVehicles();
        // this.mostRecentChangesService.logVehicleAdded(newVehicle);
      },
      error: (err) => this.handleError('addVehicle', err),
    });
  }

  getVehicles(): Observable<Vehicle[]> {
    return this.api
      .getAPI<Vehicle[]>('vehicles')
      .pipe(map((response) => response.body || []));
  }

  getAll(): void {
    this.api.getAPI<Vehicle[]>('vehicles').subscribe(
      (res: any) => {
        this.vehicles = res.body;
        this.vehicles$.next(this.vehicles);
      },
      (error) => {
        console.error('Error fetching vehicles:', error);
      }
    );
  }

  updateVehicle(vehicle_id: number, vehicle: Vehicle): Observable<any> {
    return this.api.updateAPI<Vehicle>('vehicles', vehicle_id, vehicle).pipe(
      tap((res: any) => {
        // this.mostRecentChangesService.logVehicleUpdated(vehicle);
        console.log('Vehicle updated', res);
      }),
      switchMap(() => this.getVehicles())
    );
  }

  deleteVehicle(id: number): Observable<any> {
    const vehicle = this.vehicles.find((v) => v.id === id);
    return this.api.deleteAPI<any>('vehicles', id).pipe(
      tap((res) => {
        if (vehicle) {
          // this.mostRecentChangesService.logVehicleDeleted(vehicle);
        }
        console.log('Vehicle deleted', res);
      }),
      switchMap(() => this.getVehicles())
    );
  }

  private refreshVehicles(): void {
    this.getVehicles().subscribe({
      next: (vehicles) => {
        this.vehicles = vehicles;
        this.vehicles$.next(this.vehicles);
      },
      error: (err) => this.handleError('refreshVehicles', err),
    });
  }

  private handleError<T>(
    operation = 'operation',
    error: any,
    result?: T
  ): Observable<T> {
    console.error(`${operation} failed: ${error.message}`);
    return of(result as T);
  }

  getVehicleByReference(reference: string) {
    return this.api.getAPI<Vehicle>(`vehicle/${reference}`)
  }

  updateVehicles(vehicles: Vehicle[]) {
    this.vehicles$.next(vehicles);
  }
}
