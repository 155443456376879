import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { ServiceReminderService } from 'src/app/_services/service-reminder.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-update-service-reminder',
  templateUrl: './update-service-reminder.component.html',
  styleUrls: ['./update-service-reminder.component.scss']
})
export class UpdateServiceReminderComponent implements OnInit {
  data: any;
  vehicle_id: any;
  date: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  vehicle_references: any = [];
  vehicleList: any[] = [];
  loading: boolean = false;
  services$: Subject<any> = new Subject<any>();
  serviceForm = this.fb.group({
    service_delta: [''],
    service_due_date: [''],
    vehicle_reference: [''],
    vehicle_id: [''],
    km_reading: [''],
  });

  constructor(
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    private vehiclesService: VehiclesService,
    private serviceReminderService: ServiceReminderService,
    private ref: DynamicDialogRef,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.vehiclesService.getVehicles().subscribe((vehicles: any[]) => {
      this.vehicleList = vehicles.map(vehicle => ({ vehicle_reference: vehicle.vehicle_reference }));
    });

    this.data = this.config.data;
    // populate the form with the data
    this.serviceForm.patchValue({
      service_delta: this.data.service_delta,
      service_due_date: this.data.service_due_date,
      vehicle_reference: this.data.vehicle_reference,
      vehicle_id: this.data.vehicle_id,
      km_reading: this.data.km_reading
    });
  }

  updateReminder() {
    this.loading = true;
    let vehicle_reference: string = this.serviceForm.get('vehicle_reference')!.value!;
    this.vehiclesService.getVehicleByReference(vehicle_reference).subscribe((data) => {
      this.vehicle_id = data.body.id;
      this.date = formatDate(this.serviceForm.get('service_due_date')!.value!, 'yyyy-MM-dd', 'en');
      this.serviceForm.patchValue({
        vehicle_id: this.vehicle_id,
        service_due_date: this.date
      });
      this.serviceReminderService.updateReminder(this.serviceForm.value, this.data.id).subscribe((data) => {
        this.serviceReminderService.getReminders().subscribe((data) => {
          this.serviceReminderService.updateReminders(data);
        });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating the service reminder' });
        this.ref.close();
      }, () => {
        this.ref.close();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Service reminder updated successfully' });
      });
    });   
  }

}
