import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableComponent } from './main-application/components/table/table.component';
import { HomeComponent } from './main-application/pages/home/home.component';
import { ThingstodoComponent } from './main-application/pages/thingstodo/thingstodo.component';
import { LoginComponent } from './auth/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';
import { TechnicalComponent } from './main-application/pages/technical/technical.component';
import { VehicleDetailsComponent } from './main-application/pages/vehicle-details/vehicle-details.component';
import { DateAsAgoPipe } from './_pipes/date-as-ago.pipe';
import { ActiveIssueFormComponent } from './main-application/components/active-issues/active-issue-form/active-issue-form.component';
import { ActiveIssueTableComponent } from './main-application/components/active-issues/active-issue-table/active-issue-table.component';
import { PrimeModule } from './prime.module';
import { DialogService } from 'primeng/dynamicdialog';
import { FormComponent } from './main-application/components/form/form.component';
import { NoteDialogComponent } from './main-application/components/active-issues/note-dialog/note-dialog.component';
import { SignoffIssueComponent } from './main-application/components/active-issues/signoff-issue/signoff-issue.component';
import { ForgotPasswordComponent } from './auth/Forgot-password/forgot-password/forgot-password.component';
import { RefreshComponent } from './main-application/refresh/refresh.component';
import { CreateVehicleComponent } from './main-application/pages/create-vehicle/create-vehicle.component';
import { ServiceReminderComponent } from './main-application/components/service-reminder/service-reminder.component';
import { CreateServiceReminderComponent } from './main-application/components/service-reminder/create-service-reminder/create-service-reminder.component';
import { ServiceRemindersComponent } from './main-application/pages/service-reminders/service-reminders.component';
import { ServiceDetailsComponent } from './main-application/components/service-reminder/service-details/service-details.component';
import { UpdateServiceReminderComponent } from './main-application/components/service-reminder/update-service-reminder/update-service-reminder.component';
import { ActiveIssueReportDialogComponent } from './main-application/components/active-issues/active-issue-report-dialog/active-issue-report-dialog.component';
import { HotkeysComponent } from './main-application/components/hotkeys/hotkeys.component';
import { HotkeyPageComponent } from './main-application/pages/hotkey-page/hotkey-page.component';
import { CreateHotkeyComponent } from './main-application/components/hotkeys/create-hotkey/create-hotkey.component';
import { UpdateHotkeyComponent } from './main-application/components/hotkeys/update-hotkey/update-hotkey.component';
import { CreateTagDialogComponent } from './main-application/components/hotkeys/create-tag-dialog/create-tag-dialog.component';
import { UpdateTagDialogComponent } from './main-application/components/hotkeys/update-tag-dialog/update-tag-dialog.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    TableComponent,
    HomeComponent,
    FormComponent,
    ThingstodoComponent,
    LoginComponent,
    TechnicalComponent,
    VehicleDetailsComponent,
    DateAsAgoPipe,
    ActiveIssueFormComponent,
    ActiveIssueTableComponent,
    NoteDialogComponent,
    SignoffIssueComponent,
    ForgotPasswordComponent,
    RefreshComponent,
    CreateVehicleComponent,
    ServiceReminderComponent,
    CreateServiceReminderComponent,
    ServiceRemindersComponent,
    ServiceDetailsComponent,
    UpdateServiceReminderComponent,
    ActiveIssueReportDialogComponent,
    HotkeysComponent,
    HotkeyPageComponent,
    CreateHotkeyComponent,
    UpdateHotkeyComponent,
    CreateTagDialogComponent,
    UpdateTagDialogComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    StyleClassModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    // prime module
    PrimeModule,
  ],
  providers: [
    DialogService,
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

