import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Custom validator function to check if input is a 4-digit number
export function yearModelValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null; // Don't validate empty values to allow the required validator to handle them
        }
        const isValid = /^[0-9]{4}$/.test(value);
        return isValid ? null : { yearModelInvalid: true };
    };
}