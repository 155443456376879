<div
  *ngIf="this.loadingHotkey$ | async; else loadingHotkeys"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>
<ng-template #loadingHotkeys>
  <p-toast></p-toast>
  <div
    class="block overflow-hidden surface-overlay w-full"
    style="max-width: 100vw"
  >
    <p-table
      #dt
      [value]="(hotkey$ | async) || []"
      [rowHover]="true"
      responsiveLayout="scroll"
      scrollHeight="75vh"
      scrollDirection="both"
      [scrollable]="true"
      [responsive]="true"
      [tableStyle]="{ 'min-width': '70rem' }"
      styleClass="p-datatable-sm lg:p-datatable-lg"
      [globalFilterFields]="[
        'hotkey_main',
        'main_description',
        'descriptions.tag_description',
        'descriptions.hotkey_tag'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="filterGlobal($event)"
              placeholder="Search keyword"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th
            class="bg-bluegray-700 border-right-3 m-8"
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
          >
            <span class="text-white text-lg mr-8">{{ col.header }} </span>
          </th>
          <th class="bg-bluegray-700"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-hotkey>
        <tr class="h-8rem">
          <td>
            <span>{{ hotkey.hotkey_main }}</span>
          </td>
          <td>
            <span>{{ hotkey.main_description }}</span>
          </td>
          <td>
            <div class="flex flex-wrap align-items-center">
              <div *ngFor="let description of hotkey.descriptions">
                <p-chip *ngIf="description.hotkey_tag" styleClass="p-1 mr-2 mb-2"
                  ><span class="font-bold mr-2 mb-2"
                    >{{ description.hotkey_tag }}:</span
                  >
                  {{ description.tag_description }}
                  <button
                    type="button"
                    pButton
                    icon="pi pi-pencil"
                    class="p-button-text p-button-primary border-round"
                    (click)="showUpdateTagDialog(description)"
                  ></button>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-times"
                    class="p-button-text p-button-danger border-round"
                    (click)="deleteTag(description)"
                  ></button>
                </p-chip>
              </div>
            </div>
          </td>
          <td>
            <button
              type="button"
              pButton
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-secondary text-gray-800"
              (click)="
                menu.toggle($event);
                storeRowDetail(hotkey);
                $event.stopPropagation()
              "
            ></button>
            <p-menu
              #menu
              [model]="items"
              [popup]="true"
              appendTo="body"
            ></p-menu>
            <p-menu #menu appendTo="body" [popup]="true"></p-menu>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">
            <div class="flex align -items-center justify-content-center">
              <span class="text-bluegray-800 font-medium text-xl"
                >No hotkeys</span
              >
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-confirmDialog #cd>
    <p-footer>
      <button
        pButton
        type="button"
        label="No"
        class="bg-bluegray-700"
        (click)="cd.reject()"
      ></button>
      <button
        pButton
        type="button"
        label="Yes"
        class="bg-bluegray-700"
        (click)="cd.accept()"
      ></button>
    </p-footer>
  </p-confirmDialog>
</ng-template>
<div class="floating-button">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-plus"
    class="p-button-rounded p-button-info p-button-sm bg-bluegray-700 hover:bg-bluegray-500"
    (click)="showCreateHotkeyDialog()"
  >
    <span class="p-2">Add Hotkey</span>
  </button>
</div>
<p-confirmDialog key="archiveIssue"></p-confirmDialog>
