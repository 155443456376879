import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('user') || '{}')
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/login`,

        new URLSearchParams({
          username: username,
          password: password,
        }),

        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        }
      )
      .pipe(
        map((user) => {
          // console.log('user on login', user);
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          // this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    // todo: add revoke token endpoint if possible
    // this.stopRefreshTokenTimer();
    localStorage.removeItem('user');

    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }

  // refreshToken() {
  //   let formData = new FormData();
  //   console.log('refreshing token');

  //   let userNew = JSON.parse(localStorage.getItem('user') || '{}');

  //   console.log('user on refresh', userNew);

  //   // this.userSubject.next(userNew);
  //   let data: any = {
  //     refresh: userNew.refresh,
  //   };

  //   // data = JSON.stringify(data);

  //   formData.append('refresh', userNew.refreshToken);

  //   return this.http
  //     .post<any>(`${environment.apiUrl}/auth/jwt/refresh/`, data, {
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .pipe(
  //       map((user) => {
  //         // console.log('user', user);
  //         let userNew = { ...user, refresh: this.userValue.refreshToken };
  //         // user.refresh = this.userValue.refreshToken;
  //         // console.log('user on refresh', userNew);
  //         // store user details and jwt token in local storage to keep user logged in between page refreshes
  //         localStorage.setItem('user', JSON.stringify(userNew));
  //         this.userSubject.next(userNew);

  //         this.startRefreshTokenTimer();
  //         return user;
  //       })
  //     );
  // }

  // private refreshTokenTimeout?: NodeJS.Timeout;

  // private startRefreshTokenTimer() {
  //   // parse json object from base64 encoded jwt token
  //   console.log(this.userValue);
  //   const jwtToken = JSON.parse(
  //     atob(this.userValue.access.split('.')[1])
  //   ) as any;

  //   // set a timeout to refresh the token a minute before it expires
  //   const expires = new Date(jwtToken.exp * 1000);
  //   console.log('expires', (expires.getTime() - Date.now()) / 1000);

  //   const timeout = expires.getTime() - Date.now() - 60 * 1000;
  //   console.log('timeout', timeout);
  //   this.refreshTokenTimeout = setTimeout(
  //     () => this.refreshToken().subscribe(),
  //     1000
  //     // timeout
  //   );
  // }

  // private stopRefreshTokenTimer() {
  //   clearTimeout(this.refreshTokenTimeout);
  // }
}
