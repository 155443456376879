<div
  *ngIf="this.createVehicles$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>
<ng-template #loadedContent>
  <form [formGroup]="vehicleForm" (ngSubmit)="onAddVehicleFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-12">
          <label for="vehicleYear">Vehicle Reference</label>
          <input
            formControlName="vehicle_reference"
            type="text"
            class="form-control"
            id="vehicleYear"
            placeholder="Enter vehicle reference"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              vehicleForm.get('vehicle_reference')?.invalid &&
              (vehicleForm.get('vehicle_reference')?.touched ||
                vehicleForm.get('vehicle_reference')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Vehicle Reference</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="vehicleName">Vehicle Model</label>
          <input
            formControlName="model"
            type="text"
            class="form-control"
            id="vehicleName"
            placeholder="Enter vehicle model"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              vehicleForm.get('model')?.invalid &&
              (vehicleForm.get('model')?.touched ||
                vehicleForm.get('model')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Vehicle Model</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="vehicleType">Vehicle Chassis Number</label>
          <input
            formControlName="chassis_no"
            type="text"
            class="form-control"
            id="vehicleType"
            placeholder="Enter chassis number"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              vehicleForm.get('chassis_no')?.invalid &&
              (vehicleForm.get('chassis_no')?.touched ||
                vehicleForm.get('chassis_no')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Chassis Number</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="vehicleModel">Vehicle Engine Number</label>
          <input
            formControlName="engine_no"
            type="text"
            class="form-control"
            id="vehicleModel"
            placeholder="Enter engine number"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              vehicleForm.get('engine_no')?.invalid &&
              (vehicleForm.get('engine_no')?.touched ||
                vehicleForm.get('engine_no')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Engine Number</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="vehicleYear">Vehicle Year Model</label>
          <input
            formControlName="year_model"
            type="text"
            class="form-control"
            id="vehicleYear"
            placeholder="Enter vehicle year"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              vehicleForm.get('year_model')?.invalid &&
              (vehicleForm.get('year_model')?.touched ||
                vehicleForm.get('year_model')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Year Model</strong> is required and must be a 4-digit
              number e.g. 2015</small
            >
          </div>
        </div>
        <div class="field mb-4 col-6">
          <label for="fine_amount"> Date in service </label>
          <p-calendar
            formControlName="date_inservice"
            dateFormat="yy-mm-dd"
            dataType="string"
            [inline]="false"
            [touchUI]="true"
          ></p-calendar>
        </div>
        <div class="field mb-4 col-6">
          <label for="fine_amount"> Date out service </label>
          <p-calendar
            formControlName="date_outservice"
            dateFormat="yy-mm-dd"
            dataType="string"
            [inline]="false"
            [touchUI]="true"
          ></p-calendar>
        </div>
        
        <div class="flex flex-row col-12 m-0 p-0">
          <div class="col-4">
            <button
              pButton
              pRipple
              label="Save"
              class="bg-bluegray-700 text-white mr-2"
              [disabled]="loading"
              [loading]="loading"
              type="submit"
            ></button>
          </div>
          <div class="col-4">
            <button
              pButton
              pRipple
              label="Cancel"
              class="bg-bluegray-700 text-white"
              type="button"
              [disabled]="loading"
              [loading]="loading"
              (click)="ref.close()"
            ></button>
          </div>
        </div>
        
      </div>
    </div>
  </form>
</ng-template>
