import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StatisticsService {
    constructor(private api: ApiService) { }

    getVehicleStatistics(): any {
        return this.api.getAPI<any>('statistics/vehicles').pipe(
            map((res: any) => {
                return res.body;
            })
        );
    }

    getActiveIssueStatistics(): any {
        return this.api.getAPI<any>('statistics/activeissues').pipe(
            map((res: any) => {
                return res.body;
            })
        );
    }
}