import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-reminders',
  templateUrl: './service-reminders.component.html',
  styleUrls: ['./service-reminders.component.scss']
})
export class ServiceRemindersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
