<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-truck text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl"
            >Selected Vehicle
            {{ technicalViewService.activeVehicle.vehicle_ID }}</span
          >
        </div>
        <div class="flex flex-1 justify-content-end align-items-center">
          <!-- <button
            pButton
            class="btn btn-primary"
            icon="pi pi-search"
            label="View Archive"
          ></button> -->
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <button
          pButton
          pRipple
          type="button"
          label="Add Technical Record"
          class="p-button-rounded p-button-outlined p-button-success flex"
          icon="pi pi-plus"
        ></button>
      </div>
    </div>

    <!-- vehicle side bar -->
    <div class="col-3">
      <div class="surface-card shadow-2 flex p-3 flex-column">
        <div class="font-medium text-3xl text-900 mb-3">
          {{ technicalViewService.activeVehicle.vehicle_ID }}
        </div>
        <div class="text-500 mb-5">
          <!-- more details below -->
        </div>
        <ul class="list-none p-0 m-0 border-top-1 surface-border">
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Chassis No</div>
            <div class="text-900 w-full md:w-8">
              {{ technicalViewService.activeVehicle.chassis_no }}
            </div>
          </li>
          <li
            class="flex align-items-center py-3 px-2 flex-wrap surface-ground"
          >
            <div class="text-500 w-full md:w-4 font-medium">Model</div>
            <div class="text-900 w-full md:w-8">
              {{ technicalViewService.activeVehicle.year_model }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Chassis No</div>
            <div class="text-900 w-full md:w-8">
              {{ technicalViewService.activeVehicle.chassis_no }}
            </div>
          </li>
          <li
            class="flex align-items-center py-3 px-2 flex-wrap surface-ground"
          >
            <div class="text-500 w-full md:w-4 font-medium">Engine No</div>
            <div class="text-900 w-full md:w-8">
              {{ technicalViewService.activeVehicle.engine_no }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- technical table workspace -->
    <div class="col-9">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <app-table
          [dataConfig]="{ dataType: 'technical' }"
          [data]="serviceRecords.serviceRecords$"
        ></app-table>
      </div>
    </div>
  </div>
</div>
