import { Component, OnInit } from '@angular/core';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { ServiceReminderService } from 'src/app/_services/service-reminder.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit {
  data: any;

  constructor(
    private serviceReminderService: ServiceReminderService,
    private vehiclesService: VehiclesService,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.data = this.config.data;
    console.log(this.data);
    
  }

}
