<div class="flex card-container m-5">
  <div class="flex-grow-1 flex align-items-center justify-content-center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-2">
        <!-- <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
        <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        
      </div>

      <div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <label for="username" class="block text-900 font-medium mb-2"
            >Username</label
          >
          <input
            type="text"
            formControlName="username"
            class="form-control"
            pInputText
            class="w-full mb-3"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
          />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div class="fadein animation-duration-1000 text-pink-500 mb-3 font-bold" *ngIf="f.username.errors.required">Username is required</div>
          </div>

          <label for="password" class="block text-900 font-medium mb-2"
            >Password</label
          >
          <input
            type="password"
            formControlName="password"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            pInputText
            class="w-full mb-3"
          />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div class="fadein animation-duration-1000 text-pink-500 font-bold" *ngIf="f.password.errors.required">Password is required</div>
          </div>

          <div
            class="flex align-items-center justify-content-between mb-6"
          ></div>

          <button   
            pButton
            pRipple
            [disabled]="loading"
            [loading]="loading"
            label="Sign In"
            class="w-full bg-bluegray-800 mb-4"
          >
          </button>
          <div *ngIf="submitted && f.password.errors && f.username.errors" class="invalid-feedback">
            <div class="fadein animation-duration-1000 text-pink-500 font-bold mb-2">
              Please enter a valid username and password
            </div>
          </div>
        </form>
      </div>
      <!-- <a
        class="font-medium no-underline text-blue-900 text-right cursor-pointer"
        [routerLink]="['/forgot-password']"
        >Forgot password?</a
      > -->
    </div>
  </div>
</div>
