<div
  *ngIf="this.loadingSignOff$ | async"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>
<form
  [formGroup]="this.signedOffService.signOffForm"
  (ngSubmit)="onSignOffIssueFormSubmit()"
>
  <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
    <div class="grid formgrid p-fluid">
      <div class="mb-4 mt-2 col-12">
        <div *ngIf="this.editFormGroups.length > 0">
          <h3>Selected Issues:</h3>
          <div class="flex flex-row flex-wrap overflow-hidden mb-2">
            <div class="mb-4 ml-2" *ngFor="let data of issueData">
              <p-chip
                [label]="data.vehicle_reference + ': ' + data.description"
              ></p-chip>
            </div>
          </div>
        </div>
        <label for="signoff_Type">Sign Off type</label>
        <p-dropdown
          placeholder="Select a Sign Off Type"
          formControlName="signoff_type"
          optionLabel="signoff_type"
          [options]="signOfTypes"
          optionValue="signoff_type"
          [(ngModel)]="selectedSignOffType"
        ></p-dropdown>
        <div
          *ngIf="
            signedOffService.signOffForm.get('signoff_type')?.invalid &&
            (signedOffService.signOffForm.get('signoff_type')?.touched ||
              signedOffService.signOffForm.get('signoff_type')?.dirty)
          "
        >
          <small class="p-error block"
            ><strong>Sign Off Type</strong> is required</small
          >
        </div>
      </div>
      <div *ngIf="selectedSignOffType == 'Fines'" class="field mb-4 col-12">
        <label for="fine_amount"> Fine Amount </label>
        <input
          [(ngModel)]="fine_amount"
          pInputText
          formControlName="fine_amount"
        />
        <div
          *ngIf="
            signedOffService.signOffForm.get('fine_amount')?.invalid &&
            (signedOffService.signOffForm.get('fine_amount')?.touched ||
              signedOffService.signOffForm.get('fine_amount')?.dirty)
          "
        >
          <small class="p-error block"
            ><strong>Fine Amount</strong> is required</small
          >
        </div>
      </div>
      <div class="field mb-4 col-12">
        <label for="fine_amount"> Date </label>
        <p-calendar
          formControlName="date_signed_off"
          dateFormat="yy-mm-dd"
          dataType="string"
          [inline]="false"
          [touchUI]="true"
        ></p-calendar>
        <div
          *ngIf="
            signedOffService.signOffForm.get('date_signed_off')?.invalid &&
            (signedOffService.signOffForm.get('date_signed_off')?.touched ||
              signedOffService.signOffForm.get('date_signed_off')?.dirty)
          "
        >
          <small class="p-error block"
            ><strong>Date</strong> is required</small
          >
          </div>
      </div>
      <div class="flex flex-row col-12 m-0 p-0">
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Save"
            class="bg-bluegray-700 text-white mr-2"
            [disabled]="loading"
            [loading]="loading"
            type="submit"
          ></button>
        </div>
        <div class="col-4">
          <button
            pButton
            pRipple
            label="Cancel"
            class="bg-bluegray-700 text-white"
            type="button"
            [disabled]="loading"
            [loading]="loading"
            (click)="ref.close()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
