import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TechnicalViewService {
  activeVehicle: any = {};
  constructor(private router: Router) {}

  setActiveVehicle($event: any) {
    this.activeVehicle = $event;
    this.router.navigate(['/technical']);
  }
}
