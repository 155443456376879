<form action="" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid w-full">
    <div
      class="flex flex-column lg:flex-row justify-content-center align-content-center col-12 w-full"
    >
      <div class="flex flex-column justify-content-center w-full">
        <div class="mt-2 mb-2">
          <input
            pInputText
            placeholder="Enter Email"
            type="text"
            id="email"
            name="email"
            class="w-full mt-2 mb-2"
            formControlName="email"
          />
          <div *ngIf="form.invalid && form.touched" class="w-full">
            <p-message severity="error" text="Please enter a valid email"></p-message>
          </div>
        </div>
        <div>
          <button
            pButton
            class="bg-bluegray-500 hover:bg-bluegray-700 w-full"
            label="Send Report"
            type="submit"
            [loading]="loading"
          ></button>
        </div>
        
      </div>

      <div class="mt-5 mb-5 lg:ml-5">
        <p-divider align="center" layout="vertical">
          <b>OR</b>
        </p-divider>
      </div>

      <div class="flex justify-content-center align-items-center w-full">
        <div>
          <button
            pButton
            class="bg-bluegray-500 hover:bg-bluegray-700"
            label="Download Report"
            [loading]="this.issuesService.loading"
            (click)="generate_excel_report()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
