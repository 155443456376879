<form [formGroup]="serviceForm" (ngSubmit)="updateReminder()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-6">
          <label for="serviceReminder">Service Delta</label>
          <input
            pInputText
            type="text"
            id="serviceReminder"
            name="serviceReminder"
            class="w-full"
            formControlName="service_delta"
          />
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="serviceReminder">Service Due Date</label>
          <p-calendar
            type="text"
            id="serviceReminder"
            name="serviceReminder"
            class="w-full"
            formControlName="service_due_date"
            dateFormat="yy-mm-dd"
            [inline]="false"
            [(ngModel)]="date"
            appendTo="body"
          ></p-calendar>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="serviceReminder">Vehicle Reference</label>
          <p-dropdown
            placeholder="Select a Vehicle"
            formControlName="vehicle_reference"
            optionLabel="vehicle_reference"
            [options]="vehicleList"
            optionValue="vehicle_reference"
            appendTo="body"
          ></p-dropdown>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="serviceReminder">KM Reading</label>
          <input
            pInputText
            inputId="minmax-buttons"
            id="serviceReminder"
            name="serviceReminder"
            class="w-full"
            formControlName="km_reading"
          >
        </div>
      </div>
      <button
        pButton
        pRipple
        type="submit"
        label="Update Service Reminder"
        class="bg-bluegray-700 text-white"
        [disabled]="loading"
        [loading]="loading"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
      </button>
    </div>
  </form>
  